/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

import { useSpring, animated } from "react-spring"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  const props = useSpring({ opacity: 1, from: { opacity: 0 } })
const [flipped, set] = useState(false)
const { transform, opacity } = useSpring({
  opacity: flipped ? 1 : 0,
  transform: `perspective(600px) rotateX(${flipped ? 180 : 0}deg)`,
  config: { mass: 5, tension: 500, friction: 80 },
})


  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 0,
        }}
      >
        <main>{children}</main>

        <footer>
          <br />
          <br />
          <a href="https://www.armyofquants.com">
            <animated.div style={props}>
              © {new Date().getFullYear()}, Built by
              {` `} AOQ
            </animated.div>
          </a>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
