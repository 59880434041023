import React from "react"
import styled from "styled-components"
import Container from "./container"
import Typography from "@material-ui/core/Typography"
import { Spring, config } from "react-spring"
import Typist from "react-typist"

const ViewHeight = styled.div`
  height: 100vh;
  @media only screen and (min-width: 627px) {
    padding-top: 6em;
    text-align: center;
  }
  @media only screen and (min-width: 401px) and (max-width: 626px) {
    padding-top: 8em;
    text-align: left;
    h1 {
      font-size: 60px;
    }
  }
  @media only screen and (max-width: 400px) {
    padding-top: 5em;
    text-align: left;
    height: 80vh;
    h1 {
      text-align: left;
      font-size: 60px;
    }
  }
`

const Background = styled.div`
  max-width: 1920px;
  background: #252222;
`

const Landing = () => {
  return (
    <Background>
      <Container>
        <ViewHeight>
          <Spring
            id="home"
            config={config.slow}
            from={{ opacity: 0 }}
            to={{ opacity: 1 }}
          >
            {props => (
              <Typography
                variant="h1"
                style={props}
                color="primary"
                gutterBottom={true}
              >
                Welcome!
              </Typography>
            )}
          </Spring>
          <Typist cursor={{ hideWhenDone: true }}>
            <Typography
              variant="h1"
              color="primary"
              style={{
                display: "inline",
                color: "white",
              }}
              gutterBottom={true}
            >
              We want to help you date safely
            </Typography>
            <br />
            <Typography
              variant="h1"
              color="primary"
              gutterBottom={true}
              style={{ display: "inline" }}
            >
              Get started by providing some information abour your date
            </Typography>
            <Typist.Backspace count={8} delay={200} />
            <Typography
              variant="h1"
              color="primary"
              style={{ display: "inline" }}
              gutterBottom={true}
            >
              Date safely
            </Typography>
            <Typist.Backspace count={28} delay={200} />
            <Typography
              variant="h1"
              color="primary"
              style={{ display: "inline" }}
              gutterBottom={true}
            >
              Upload a picture and our AI will return what it finds
            </Typography>
            <Typist.Backspace count={36} delay={200} />
            <Typography
              variant="h1"
              style={{ display: "inline", color: "white" }}
              gutterBottom={true}
            >
             Is My Date Safe ? Don't wonder anymore. 
            </Typography>
          </Typist>
        </ViewHeight>
      </Container>
    </Background>
  )
}

export default Landing
