import React from "react"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Card from "@material-ui/core/Card"
import cardGrid from "@material-ui/core/Card"
import Grid from "@material-ui/core/Grid"
import CardActionArea from "@material-ui/core/CardActionArea"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import Container from "@material-ui/core/Container"
import Landing from "../components/landing";
import Typography from "@material-ui/core/Typography"
import "./mystyles.scss"
import GenericWoman from "../images/1Woman.png";
import GenericMan from "../images/1Man.png"
import CardPerson from "../components/CardPerson";


const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      keywords={["safe dating", "date check", "date identity check"]}
    />

    <h1 className="title is-2">Upload a pic to get started</h1>

    <div className="column">
      
      <p>Profiles</p>

      <div className="card">
        <div className="card-content">
          <div className="media">
            <div className="media-left">
              <figure className="image is-48x48">
                <CardPerson/>
                {/* <img src={GenericMan} alt="Placeholder image"></img> */}
              </figure>
            </div>

            <div className="media-content">
              <p className="title is-4">John Smith</p>
              <p className="subtitle is-6">@johnsmith</p>
            </div>
          </div>

          <div className="content">
            <br></br>
            Is John Smith really John Smith? Could he be Joe Smithson?
            <a>@IsMyDateSafe</a>.<a href="#">#IsMyDateSafe</a> <br></br>
            <time datetime="2019-9-2">11:09 PM - 2 Sept 2019</time>
          </div>
        </div>
        <footer className="card-footer">
          <a href="#" class="card-footer-item">
            Basic Info
          </a>
          <a href="#" className="card-footer-item">
            Relationship Status
          </a>
          <a href="#" className="card-footer-item">
            Dating apps used
          </a>
        </footer>
      </div>

      <br></br>

      <div className="card">
        <div className="card-content">
          <div className="media">
            <div className="media-left">
              <figure className="image is-48x48">
                <img src={GenericWoman} alt="Placeholder image"></img>
              </figure>
            </div>

            <div className="media-content">
              <p className="title is-4">Eliza Doe</p>
              <p className="subtitle is-6">@ElizaDoe</p>
            </div>
          </div>

          <div className="content">
            <br></br>
            Is Eliza Smith really Eliza Doe? Could he be Eliza Smith?
            <a>@IsMyDateSafe</a>.<a href="#">#IsMyDateSafe</a> <br></br>
            <time datetime="2019-9-2">11:09 PM - 2 Sept 2019</time>
          </div>
        </div>
        <footer className="card-footer">
          <a href="#" class="card-footer-item">
            Basic Info
          </a>
          <a href="#" className="card-footer-item">
            Relationship Status
          </a>
          <a href="#" className="card-footer-item">
            Dating apps used
          </a>
        </footer>
      </div>
    </div>

    <Link to="/page-2/">Go to this page to upload a File</Link>
    <br/>
    <br/>
    <br/>

    <CardPerson />
  </Layout>
)

export default IndexPage
